const i18nRO = {
  MS_GDPR_TITLE:
    'Cerere acces de la distanta asupra sistemulului dvs. de incalzire',
  MS_GDPR_DETAILEDTITLE:
    '{company} a cerut accesul de la distanta asupra sistemului dvs. de incalzire',
  MS_GDPR_SUBTITLE:
    'Pentru ca {company} să ofere asistență la distanță pentru sistemul dumneavoastră de încălzire, datele generate de sistemul dumneavoastră de încălzire centrală trebuie prelucrate.',
  MS_USERINFO_TITLE: 'Datele tale:',
  MS_SALUTATION: 'Salutari',
  MS_SALUTATION_LABEL: 'Doamna/Domnul',
  MS_SALUTATION_MRS: 'Doamna',
  MS_SALUTATION_MR: 'Domnul',
  MS_TITLE: 'Titulatura',
  MS_FIRSTNAME: 'Prenume',
  MS_LASTNAME: 'Nume',
  MS_NAME: 'Denumire',
  MS_STREET: 'Numar apartament/casa',
  MS_HOUSENUMBER: 'Adresa',
  MS_ADDRESS_EXT: 'Adresa',
  MS_ZIPCODE: 'Codul postal',
  MS_CITY: 'Orasul',
  MS_COUNTRY: 'Tara',
  MS_PHONE: 'Numarul de telefon de contact',
  MS_EMAIL: 'e-mail',
  MS_MANDATORY: 'Campuri obligatorii',
  MS_PHONE_TOO_SHORT: 'Number too short',
  MS_PHONE_TOO_LONG: 'Number too long',
  MS_CONFIRMATION_TITLE: 'Confirmarea declaratiei de consimtamant',
  MS_CONFIRMATION_PRE_TEXT: 'Prin prezenta confirma ca',
  MS_CONFIRMATION_TEXT: 'datele procesate de catre instalator/Firma de service',
  MS_CONFIRMATION_POST_TEXT: '{company}',
  MS_CONFIRMATION_BUTTON: 'CONFIRMARE SI TRIMITERE',
  MS_IMPRINT: 'Note legale',
  MS_GDPR: 'Date de confidentialitate',
  MS_TERMS: 'Termeni de utilizare',
  MS_VAILLANT_LOGO_MOTO: 'Confortul casei tale',
  MS_DATA_PROTECTION: 'Confidentialitate',
  MS_REQUIRED: 'Camp obligatoriu',
  MS_REQUIRED_EXPLANATION:
    '*Acest camp este obligatoriu si trebuie completat pentru a putea finaliza procesul de inregistrare',
  MS_CLOSE_BUTTON: 'De acord',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Ne pare rau, va rog incepeti cu numarul',
  MS_EXPANDABLE_TITLE: 'Declariatia de consimtamant',
  MS_ABOUT_US: 'Despre noi',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Doresc sa vizitez {0} pagina web',
  MS_OPTOUT_SALUTATION: 'Stimate client',
  MS_OPTOUT_QUESTION:
    'Doriti revocarea confirmarii declaratiei dvs de consimtamant?',
  MS_OPTOUT_CHECKBOX:
    'Inteleg ca instalatorul/firma de service nu vor mai putea accesa datele mele',
  MS_OPTOUT_CONFIRM: 'Da, revocare!',
  MS_OPT_IN_SUCCESS:
    'Consimtamantul dumneavoastra a fost inregistrat cu succes!',
  MS_OPTOUT_SUCCESS: 'Optiunea revocarii a fost inregistrata cu succes.',
  MS_OPTOUT_FAIL:
    'Au aparut erori in timpul procesarii cererii dvs. de revocare.Va rugam sa contactati serviciul de asistenta!',
  MS_IMPRINT_TITLE: 'Termeni legali',
  MS_IMPRINT_PROVIDER: 'Furnizor',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Telefon:',
  MS_IMPRINT_LTD: 'Compania',
  MS_IMPRINT_REGISTERED: 'Sediul',
  MS_IMPRINT_REGISTRYCOURT: 'Inregistrata la:',
  MS_IMPRINT_MANAGING: 'Manager',
  MS_IMPRINT_CHAIRMAN: 'Presedintele consiliului de administrare:',
  MS_IMPRINT_VAT: 'Platitor de TVA',
  MS_GENERIC_ERROR_TITLE: 'Ooops!',
  MS_GENERIC_ERROR_EXPLANATION:
    'In acest moment a apatut o problema de natura tehnica, vă rugăm să încercați din nou.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE: 'Ne pare rau, nu putem procesa acesta cerere',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Va rugam verificati daca ati introdus corect adresa URL primita pe adresa de e-mail.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'În întreținere',
  MS_MAINTENANCE_MESSAGE:
    'În prezent, suntem în curs de întreținere. Fereastra de întreținere se încheie la {endOfMaintenanceWindow, date, medium} la {endOfMaintenanceWindow, time, short}.',
};

export default i18nRO;
