const i18nDE = {
  MS_GDPR_TITLE: 'Einwilligungserklärung zur Datenverarbeitung',
  MS_GDPR_DETAILEDTITLE:
    'Einwilligungserklärung zur Datenverarbeitung durch {company}',
  MS_GDPR_SUBTITLE:
    'Damit {company} Ihre Heizungsanlage aus der Ferne betreuen kann, ist die Zustimmung zur Datenverarbeitung Ihres Heizgerätes erforderlich.',
  MS_USERINFO_TITLE: 'Ihre Daten:',
  MS_SALUTATION: 'Anrede',
  MS_SALUTATION_LABEL: 'Frau/Herr',
  MS_SALUTATION_MRS: 'Frau',
  MS_SALUTATION_MR: 'Herr',
  MS_TITLE: 'Titel',
  MS_FIRSTNAME: 'Vorname',
  MS_LASTNAME: 'Nachname',
  MS_NAME: 'Name',
  MS_STREET: 'Straße',
  MS_HOUSENUMBER: 'Hausnummer',
  MS_ADDRESS_EXT: 'Adresszusatz',
  MS_ZIPCODE: 'PLZ',
  MS_CITY: 'Stadt',
  MS_COUNTRY: 'Land',
  MS_PHONE: 'Telefonnummer',
  MS_EMAIL: 'E-Mail',
  MS_MANDATORY: 'Pflichtfelder',
  MS_PHONE_TOO_SHORT: 'Telefonnummer zu kurz',
  MS_PHONE_TOO_LONG: 'Telefonnummer zu lang',
  MS_CONFIRMATION_TITLE: 'Einwilligungserklärung bestätigen',
  MS_CONFIRMATION_PRE_TEXT: 'Hiermit willige ich in die',
  MS_CONFIRMATION_TEXT: 'Datenverarbeitung durch den Dienstleister',
  MS_CONFIRMATION_POST_TEXT: '{company} ein.',
  MS_CONFIRMATION_BUTTON: 'ZUSTIMMEN & ABSENDEN',
  MS_IMPRINT: 'Impressum',
  MS_GDPR: 'Datenschutzbestimmungen',
  MS_TERMS: 'Nutzungsbedingungen',
  MS_VAILLANT_LOGO_MOTO: 'Komfort für mein Zuhause',
  MS_DATA_PROTECTION: 'Datenschutz',
  MS_REQUIRED: 'Pflichtfeld',
  MS_REQUIRED_EXPLANATION:
    '* Dies ist ein Pflichtfeld, das zum Abschluss des Opt-ins einen Eintrag benötigt.',
  MS_CLOSE_BUTTON: 'Schließen',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Bitte beginnen Sie die Nummer mit',
  MS_EXPANDABLE_TITLE: 'Einwilligungserklärung zur Datenverarbeitung',
  MS_ABOUT_US: 'Über uns',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Ich möchte die {0} homepage besuchen.',
  MS_OPTOUT_SALUTATION: 'Sehr geehrter Kunde,',
  MS_OPTOUT_QUESTION:
    'Möchten Sie die Einwilligungserklärung zur Datenverarbeitung (opt-out) wirklich widerrufen?',
  MS_OPTOUT_CHECKBOX:
    'Ich verstehe, dass das Unternehmen dann keinen Zugriff mehr auf meine Daten mehr haben wird.',
  MS_OPTOUT_CONFIRM: 'Ja, widerrufen',
  MS_OPT_IN_SUCCESS:
    'Ihre Einwilligungserklärung wurde erfolgreich übermittelt.',
  MS_OPTOUT_SUCCESS: 'Ihr Opt-out wurde erfolgreich durchgeführt.',
  MS_OPTOUT_FAIL:
    'Bei der Übermittlung Ihres Opt-outs ist ein Problem aufgetreten. Bitte wenden Sie sich an die Support-Hotline.',
  MS_IMPRINT_TITLE: 'Impressum',
  MS_IMPRINT_PROVIDER: 'Anbieter',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Telefon:',
  MS_IMPRINT_LTD: 'Gesellschaft mit beschränkter Haftung',
  MS_IMPRINT_REGISTERED: 'Sitz:',
  MS_IMPRINT_REGISTRYCOURT: 'Registergericht:',
  MS_IMPRINT_MANAGING: 'Geschäftsführer:',
  MS_IMPRINT_CHAIRMAN: 'Vorsitzender des Aufsichtsrates:',
  MS_IMPRINT_VAT: 'USt.-Ident-Nr.',
  MS_GENERIC_ERROR_TITLE: 'Sand im Getriebe',
  MS_GENERIC_ERROR_EXPLANATION:
    'Wir haben aktuell ein technisches Problem. Bitte versuchen Sie es später nochmals.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE:
    'Leider können wir Ihre Anfrage nicht zuordnen',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Bitte prüfen Sie Ihre URL auf Vollständigkeit. Gegenenfalls hilft es, die URL aus der Email zu kopieren und in der Addresszeile des Browsers einzutragen.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'Wartung läuft',
  MS_MAINTENANCE_MESSAGE:
    'Wir befinden uns derzeit in Wartung. Das Wartungsfenster endet am {endOfMaintenanceWindow, date, medium} um {endOfMaintenanceWindow, time, short}.',
};

export default i18nDE;
