const i18nNL = {
  MS_GDPR_TITLE: 'Toestemmingsformulier voor toegang tot uw verwarmingssysteem',
  MS_GDPR_DETAILEDTITLE:
    '{company} heeft toegang op afstand gevraagd tot uw verwarmingssysteem.',
  MS_GDPR_SUBTITLE:
    'Om  {company} in staat te stellen diensten te verlenen m.b.t. gegevens van uw verwarmingssysteem, is uw toestemming nodig om data met {company} uit te mogen wisselen.',
  MS_USERINFO_TITLE: 'Gebruikersaccount informatie:',
  MS_SALUTATION: 'Aanhef',
  MS_SALUTATION_LABEL: 'Mevr./Mr.',
  MS_SALUTATION_MRS: 'Mevr.',
  MS_SALUTATION_MR: 'Mr.',
  MS_TITLE: 'Titel',
  MS_FIRSTNAME: 'Voornaam',
  MS_LASTNAME: 'Achternaam',
  MS_NAME: 'Naam',
  MS_STREET: 'Straat',
  MS_HOUSENUMBER: 'Huisnummer',
  MS_ADDRESS_EXT: 'Toevoeging',
  MS_ZIPCODE: 'Postcode',
  MS_CITY: 'Stad',
  MS_COUNTRY: 'Land',
  MS_PHONE: 'Telefoonnummer',
  MS_EMAIL: 'E-mail',
  MS_MANDATORY: 'Verplichte velden',
  MS_PHONE_TOO_SHORT: 'Nummer te kort',
  MS_PHONE_TOO_LONG: 'Nummer te lang',
  MS_CONFIRMATION_TITLE: 'Toestemming bevestigen',
  MS_CONFIRMATION_PRE_TEXT: 'Ik bevestig hierbij dat ik aan {company}',
  MS_CONFIRMATION_TEXT: 'toestemming verleen',
  MS_CONFIRMATION_POST_TEXT:
    'om de gegevens van mijn verwarmingssysteem te verwerken.',
  MS_CONFIRMATION_BUTTON: 'BEVESTIGEN EN VERZENDEN',
  MS_IMPRINT: 'Impressum',
  MS_GDPR: 'Privacyverklaring',
  MS_TERMS: 'Gebruiksvoorwaarden',
  MS_VAILLANT_LOGO_MOTO: 'Mijn huis, mijn comfort',
  MS_DATA_PROTECTION: 'Privacy',
  MS_REQUIRED: 'Verplicht veld',
  MS_REQUIRED_EXPLANATION:
    '* Dit is een verplicht veld en moet worden ingevuld om de opt in te voltooien',
  MS_CLOSE_BUTTON: 'Sluiten',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Begin het nummer met',
  MS_EXPANDABLE_TITLE: 'Toestemmingsformulier',
  MS_ABOUT_US: 'Over ons',
  MS_OPTIN_ALREADY_DONE:
    'U heeft al toestemming gegeven aan een installateur/service partij',
  MS_VISIT_HOMEPAGE: 'Ik wil naar de webpagina van {0} gaan.',
  MS_OPTOUT_SALUTATION: 'Geachte klant,',
  MS_OPTOUT_QUESTION:
    'Weet u zeker dat u de toestemming tot toegang wilt intrekken?',
  MS_OPTOUT_CHECKBOX:
    'Ja, ik begriijp dat de installateur/service partij geen toegang meer heeft tot mijn systeem ',
  MS_OPTOUT_CONFIRM: 'Ja, intrekken ',
  MS_OPT_IN_SUCCESS: 'Uw toestemming is verzonden',
  MS_OPTOUT_SUCCESS:
    'Uw intrekking tot de toestemmingsverklaring succesvol doorgevoerd',
  MS_OPTOUT_FAIL:
    'Er is iets misgegaan met de verwerking van uw verzoek. Neem contact op met het servicecenter',
  MS_IMPRINT_TITLE: 'Impressum',
  MS_IMPRINT_PROVIDER: 'Aanbieder',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Tel.:',
  MS_IMPRINT_LTD: 'Vennootschap met beperkte aansprakelijkheid',
  MS_IMPRINT_REGISTERED: 'Zet.:',
  MS_IMPRINT_REGISTRYCOURT: 'Regist.-kantoor:',
  MS_IMPRINT_MANAGING: 'Directeur:',
  MS_IMPRINT_CHAIRMAN: 'Voorzitter raad van bestuur:',
  MS_IMPRINT_VAT: 'Btw-nr.',
  MS_GENERIC_ERROR_TITLE:
    'Helaas kan uw verzoek om dit moment niet worden uitgevoerd.',
  MS_GENERIC_ERROR_EXPLANATION:
    'Wegens technische omstandigheden kan uw verzoek om dit moment niet worden uitgevoerd. Probeer het later nogmaals.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE:
    'Helaas, uw verzoek kan niet worden uitgevoerd.',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Ongeldige URL: controleer of de URL overeenkomt met de link die u was gestuurd in de email.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'Onderhoud',
  MS_MAINTENANCE_MESSAGE:
    'Er vindt momenteel onderhoud plaats. Het onderhoudsvenster eindigt op {endOfMaintenanceWindow, date, medium} op {endOfMaintenanceWindow, time, short}.',
};

export default i18nNL;
