const i18nFR = {
  MS_GDPR_TITLE:
    'Demande pour un accès à distance à votre solution de chauffage',
  MS_GDPR_DETAILEDTITLE:
    '{company} demande un accès à distance à votre solution de chauffage.',
  MS_GDPR_SUBTITLE:
    "Pour que {company} puisse fournir un support à distance de votre solution de chauffage, la permission d'accéder à vos données chauffage est demandée.",
  MS_USERINFO_TITLE: "Informations sur le compte de l'utilisateur:",
  MS_SALUTATION: 'Cordialement,',
  MS_SALUTATION_LABEL: 'Mme./Mr.',
  MS_SALUTATION_MRS: 'Mme.',
  MS_SALUTATION_MR: 'Mr.',
  MS_TITLE: 'Titre',
  MS_FIRSTNAME: 'Prénom',
  MS_LASTNAME: 'Nom',
  MS_NAME: 'Nom',
  MS_STREET: "Maison/numéro d'appartement ou nom",
  MS_HOUSENUMBER: 'Adresse ligne 1',
  MS_ADDRESS_EXT: 'Adresse ligne 2',
  MS_ZIPCODE: 'Code postal',
  MS_CITY: 'Ville',
  MS_COUNTRY: 'Pays',
  MS_PHONE: 'Téléphone',
  MS_EMAIL: 'E-mail',
  MS_MANDATORY: 'Champs obligatoires',
  MS_PHONE_TOO_SHORT: 'Number too short',
  MS_PHONE_TOO_LONG: 'Number too long',
  MS_CONFIRMATION_TITLE: 'Je confirme la déclaration de consentement',
  MS_CONFIRMATION_PRE_TEXT: 'Je soussigné confirmer que',
  MS_CONFIRMATION_TEXT: 'le traitement de la donnée par votre installateur SAV',
  MS_CONFIRMATION_POST_TEXT: '{company}.',
  MS_CONFIRMATION_BUTTON: 'CONFIRME & ENVOYE',
  MS_IMPRINT: 'Imprimer',
  MS_GDPR: 'Charte de protection des données',
  MS_TERMS: 'Mentions légales',
  MS_VAILLANT_LOGO_MOTO: 'Confort pour votre domicile',
  MS_DATA_PROTECTION: 'Politique de confidentialité',
  MS_REQUIRED: 'Champs obligatoires',
  MS_REQUIRED_EXPLANATION: '* Ce champ est obligatoire',
  MS_CLOSE_BUTTON: 'Fermer',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Veuillez commencer le numéro avec',
  MS_EXPANDABLE_TITLE: 'Déclaration de consentement',
  MS_ABOUT_US: 'A propos',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Je souhaite visiter la page {0}',
  MS_OPTOUT_SALUTATION: 'Cher client,',
  MS_OPTOUT_QUESTION: 'Vous souhaitez révoquer votre consentement ?',
  MS_OPTOUT_CHECKBOX:
    "Je comprends que mon installateur SAV n'aura plus accès à mes données",
  MS_OPTOUT_CONFIRM: 'Oui, je révoque',
  MS_OPT_IN_SUCCESS: 'Votre consentement est validé.',
  MS_OPTOUT_SUCCESS: 'Votre consentement est validé.',
  MS_OPTOUT_FAIL:
    "Quelque chose n'a pas fonctionné. Veuillez contacter notre service client.",
  MS_IMPRINT_TITLE: 'Imprimer',
  MS_IMPRINT_PROVIDER: 'Fournisseur',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Téléphone :',
  MS_IMPRINT_LTD: 'Société à responsabilité limitée',
  MS_IMPRINT_REGISTERED: 'Siège :',
  MS_IMPRINT_REGISTRYCOURT: 'RCS :',
  MS_IMPRINT_MANAGING: 'Direction :',
  MS_IMPRINT_CHAIRMAN: 'Président du Conseil de surveillance :',
  MS_IMPRINT_VAT: 'N° intracommunautaire',
  MS_GENERIC_ERROR_TITLE: 'Oups',
  MS_GENERIC_ERROR_EXPLANATION:
    "Une erreur s'est produite lors du traitement de votre demande, veuillez réessayer.",
  MS_TOKEN_NOT_FOUND_ERROR_TITLE:
    'Désolé, nous ne pouvons pas gérer cette demande',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    "Vérifiez SVP que vous avez utilisé l'URL que nous vous avons envoyé par email",
  MS_MAINTENANCE_MESSAGE_TITLE: 'En maintenance',
  MS_MAINTENANCE_MESSAGE:
    'Nous sommes actuellement en maintenance. La fenêtre de maintenance se termine le {endOfMaintenanceWindow, date, medium} à {endOfMaintenanceWindow, time, short}.',
};

export default i18nFR;
