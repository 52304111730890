const i18nHR = {
  MS_GDPR_TITLE: 'Zahtjev za daljinski pristup vašem sustavu grijanja',
  MS_GDPR_DETAILEDTITLE:
    '{company}  je zatražio daljinski pristup vašem sustavu grijanja.',
  MS_GDPR_SUBTITLE:
    'Da bi {company} pružio daljinsku podršku vašeg sustav grijanja, neophodno je dopuštenje za obradu podataka vašeg uređaja za grijanje.',
  MS_USERINFO_TITLE: 'az Ön adatait:',
  MS_SALUTATION: 'Poštovani,',
  MS_SALUTATION_LABEL: 'Gđa. / Gosp.',
  MS_SALUTATION_MRS: 'Gđa.  ',
  MS_SALUTATION_MR: 'Gosp.',
  MS_TITLE: 'Zvanje',
  MS_FIRSTNAME: 'Ime',
  MS_LASTNAME: 'Prezime',
  MS_NAME: 'Név',
  MS_STREET: 'Kućni broj',
  MS_HOUSENUMBER: 'Adresa 1',
  MS_ADDRESS_EXT: 'Adresa 2',
  MS_ZIPCODE: 'PTT',
  MS_CITY: 'Grad',
  MS_COUNTRY: 'Zemlja',
  MS_PHONE: 'Telefonski broj',
  MS_EMAIL: 'E-mail',
  MS_MANDATORY: 'Obavezna polja',
  MS_PHONE_TOO_SHORT: 'Number too short',
  MS_PHONE_TOO_LONG: 'Number too long',
  MS_CONFIRMATION_TITLE: 'Potvrdite izjavu o pristanku',
  MS_CONFIRMATION_PRE_TEXT: 'Ovim putem potvrđujem',
  MS_CONFIRMATION_TEXT:
    'obradu podataka od strane vašeg servisera / davatelja usluga',
  MS_CONFIRMATION_POST_TEXT: '{company}.',
  MS_CONFIRMATION_BUTTON: 'Potvrdi&pošalji',
  MS_IMPRINT: 'Pravna obavijest',
  MS_GDPR: 'Pravila o privatnosti',
  MS_TERMS: 'Uvjeti korištenja',
  MS_VAILLANT_LOGO_MOTO: 'Za udobnost mog doma',
  MS_DATA_PROTECTION: 'Privatnost',
  MS_REQUIRED: 'Obavezna polja',
  MS_REQUIRED_EXPLANATION:
    '* Ovo polje je obavezno i potrebno ga je popuniti da biste dovršili privolu.',
  MS_CLOSE_BUTTON: 'Slažem se',
  MS_PHONE_NUMBER_VALIDATION_ERROR: 'Započnite broj sa',
  MS_EXPANDABLE_TITLE: 'Izjava o pristanku',
  MS_ABOUT_US: 'O nama',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Želim posjetiti {0} web stranicu.',
  MS_OPTOUT_SALUTATION: 'Poštovani korisniče,',
  MS_OPTOUT_QUESTION:
    'Želite li opozvati (isključiti) svoju izjavu o pristanku?',
  MS_OPTOUT_CHECKBOX:
    'Jasno mi je da serviser / pružatelj usluga više neće moći pristupiti mojem sustavu grijanja.',
  MS_OPTOUT_CONFIRM: 'Da, opozovi!',
  MS_OPT_IN_SUCCESS: 'Vaš je pristanak uspješno poslan.',
  MS_OPTOUT_SUCCESS: 'Vaš opoziv je uspješan.',
  MS_OPTOUT_FAIL:
    'Nešto se zakompliciralo tijekom obrade vašeg zahtjeva za opoziv. Molimo kontaktirajte Vaillant d.o.o.',
  MS_IMPRINT_TITLE: 'Pravne napomene',
  MS_IMPRINT_PROVIDER: 'Dobavljač',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Telefon',
  MS_IMPRINT_LTD: 'Društvo s ograničenom odgovornošću',
  MS_IMPRINT_REGISTERED: 'Registrirani ured:',
  MS_IMPRINT_REGISTRYCOURT: 'Nadležni sud',
  MS_IMPRINT_MANAGING: 'Direktor:',
  MS_IMPRINT_CHAIRMAN: 'Predsjednik Nadzornog odbora:',
  MS_IMPRINT_VAT: 'OIB:',
  MS_GENERIC_ERROR_TITLE: 'Oprostite',
  MS_GENERIC_ERROR_EXPLANATION:
    'Trenutno imamo tehnički problem. Kérjük, próbálja meg újra.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE: 'Nažalost, ne možemo obraditi ovaj zahtjev',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Provjerite koristite li ispravan URL koji vam je poslan e-poštom.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'Pod održavanjem',
  MS_MAINTENANCE_MESSAGE:
    'Trenutno smo na održavanju. Period održavanja završava {endOfMaintenanceWindow, date, medium} u {endOfMaintenanceWindow, time, short}.',
};

export default i18nHR;
