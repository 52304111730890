const i18nES = {
  MS_GDPR_TITLE: 'Solicitud de acceso remoto a su sistema de calefacción',
  MS_GDPR_DETAILEDTITLE:
    '{company} ha solicitado acceso remoto a su sistema de calefacción.',
  MS_GDPR_SUBTITLE:
    'Para que {company} pueda prestar asistencia a distancia para su sistema de calefacción, se requiere autorización para el tratamiento de datos de su aparato de calefacción.',
  MS_USERINFO_TITLE: 'Tu información:',
  MS_SALUTATION: 'Saludo',
  MS_SALUTATION_LABEL: 'Sra./Sr.',
  MS_SALUTATION_MRS: 'Señora',
  MS_SALUTATION_MR: 'Señor',
  MS_TITLE: 'Título',
  MS_FIRSTNAME: 'Nombre',
  MS_LASTNAME: 'Apellido',
  MS_NAME: 'Nombre',
  MS_STREET: 'Calle',
  MS_HOUSENUMBER: 'Número',
  MS_ADDRESS_EXT: 'Sufijo de la dirección',
  MS_ZIPCODE: 'Código postal',
  MS_CITY: 'Ciudad',
  MS_COUNTRY: 'País',
  MS_PHONE: 'Teléfono',
  MS_EMAIL: 'Email',
  MS_MANDATORY: 'Campos obligatorios',
  MS_PHONE_TOO_SHORT: 'Number too short',
  MS_PHONE_TOO_LONG: 'Number too long',
  MS_CONFIRMATION_TITLE: 'Confirmación de declaración de consentimiento',
  MS_CONFIRMATION_PRE_TEXT: 'Por la presente confirmo el',
  MS_CONFIRMATION_TEXT:
    'Procesamiento de datos por parte de su Servicio Técnico Oficial.',
  MS_CONFIRMATION_POST_TEXT: '{company}.',
  MS_CONFIRMATION_BUTTON: 'CONFIRMAR & ENVIAR',
  MS_IMPRINT: 'Créditos',
  MS_GDPR: 'Política de privacidad',
  MS_TERMS: 'Condiciones de uso',
  MS_VAILLANT_LOGO_MOTO: 'Confort para su hogar',
  MS_DATA_PROTECTION: 'Privacidad',
  MS_REQUIRED: 'Campo obligatorio',
  MS_REQUIRED_EXPLANATION:
    'Este campo es obligatorio y debe completarse para finalizar su suscripción.',
  MS_CLOSE_BUTTON: 'Estoy de acuerdo',
  MS_PHONE_NUMBER_VALIDATION_ERROR:
    'Lo sentimos, por favor comience el número con',
  MS_EXPANDABLE_TITLE: 'Declaración de consentimiento',
  MS_ABOUT_US: 'Sobre nosotros',
  MS_OPTIN_ALREADY_DONE: '_NTA_',
  MS_VISIT_HOMEPAGE: 'Quiero visitar la siguiente web: {0}.',
  MS_OPTOUT_SALUTATION: 'Estimado cliente',
  MS_OPTOUT_QUESTION:
    '¿Desea anular (rechazar) su confirmación para la declaración de consentimiento?',
  MS_OPTOUT_CHECKBOX:
    'Entiendo que el Servicio Técnico Oficial ya no podrá acceder a mis datos.',
  MS_OPTOUT_CONFIRM: 'Sí, anular',
  MS_OPT_IN_SUCCESS: 'Su consentimiento ha sido enviado con éxito.',
  MS_OPTOUT_SUCCESS: 'Su anulación se ha realizado con éxito',
  MS_OPTOUT_FAIL:
    'Se ha producido un error al procesar su solicitud de anulación. Póngase en contacto con su Servicio Técnico Oficial.',
  MS_IMPRINT_TITLE: 'Informacion legal',
  MS_IMPRINT_PROVIDER: 'Proveedor',
  MS_IMPRINT_ADDRESS: '42859 Remscheid, Germany',
  MS_IMPRINT_PHONE: 'Teléfono:',
  MS_IMPRINT_LTD: 'Sociedad Limitada',
  MS_IMPRINT_REGISTERED: 'Sede social:',
  MS_IMPRINT_REGISTRYCOURT: 'Juzgado de registro:',
  MS_IMPRINT_MANAGING: 'Directores generales:',
  MS_IMPRINT_CHAIRMAN: 'Presidente del consejo de supervisión:',
  MS_IMPRINT_VAT: 'Nº de identificación del IVA',
  MS_GENERIC_ERROR_TITLE: 'Se ha producido un error',
  MS_GENERIC_ERROR_EXPLANATION:
    'Se ha detectado problema técnico. Inténtelo de nuevo.',
  MS_TOKEN_NOT_FOUND_ERROR_TITLE:
    'Lo sentimos, no podemos tramitar su solicitud',
  MS_TOKEN_NOT_FOUND_ERROR_EXPLANATION:
    'Por favor, verifique que está utilizando la URL correcta que se le envió por correo electrónico.',
  MS_MAINTENANCE_MESSAGE_TITLE: 'En mantenimiento',
  MS_MAINTENANCE_MESSAGE:
    'Actualmente estamos en mantenimiento. La ventana de mantenimiento finaliza el {endOfMaintenanceWindow, date, medium} a las {endOfMaintenanceWindow, time, short}.',
};

export default i18nES;
